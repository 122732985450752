import { QueryHookOptions, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'

import {
  GET_NETWORK_INFO,
  GET_ROLES,
  GetNetworkQuery,
  GetNetworkQueryVariables,
  GetRolesQuery,
} from 'tribe-api/graphql'
import { Network } from 'tribe-api/interfaces'

import { QueryResult } from '../../@types'

export type UseGetNetworkResult = QueryResult<GetNetworkQuery> & {
  network: Network
}
const useGetNetwork = (
  variables: GetNetworkQueryVariables = {
    withDefaultSpaces: false,
    withRoles: true,
  },
  options?: QueryHookOptions<GetNetworkQuery, GetNetworkQueryVariables>,
): UseGetNetworkResult => {
  let { skip = false } = options || {}
  const router = useRouter()
  // Can't use useGetNetwork() on support panel home page.
  if (router.asPath.includes('network/')) {
    skip = false
  }
  const { data, error, loading, client } = useQuery<
    GetNetworkQuery,
    GetNetworkQueryVariables
  >(GET_NETWORK_INFO, {
    variables,
    skip,
  })

  const network = data?.getNetwork as Network
  if (network?.roles && network.roles.length > 0) {
    client.writeQuery<GetRolesQuery>({
      query: GET_ROLES,
      data: { getRoles: network?.roles },
    })
  }

  return {
    data,
    network,
    error,
    loading,
    isInitialLoading: loading && data === undefined,
  }
}

export default useGetNetwork
