import React from 'react'

import { Container, Grid, Box } from '@chakra-ui/react'

import { useResponsive } from 'hooks/useResponsive'

const styles = {
  left: {
    pl: [5, 6],
  },
}

export const MOBILE_HEADER_HEIGHT = '64px'

export default function MobileHeader() {
  const {
    isMobile,
    mobileHeaderParts: mobileHeader,
    isSidebarOpen,
  } = useResponsive()

  if (
    !isMobile ||
    (!mobileHeader.left && !mobileHeader.title && !mobileHeader.right)
  ) {
    return null
  }

  return (
    <Container h={MOBILE_HEADER_HEIGHT} w="100vw">
      <Grid
        position="fixed"
        w="inherit"
        h="inherit"
        left={0}
        top={0}
        bg="bg.base"
        zIndex={isSidebarOpen ? 'popover' : 'banner'}
        borderBottom="1px solid"
        borderBottomColor="border.lite"
        templateColumns="2fr 3.5fr 2fr"
        alignItems="center"
        justifyItems="center"
        {...mobileHeader.props}
      >
        <Box pl={styles.left.pl} justifySelf="start">
          {mobileHeader.left}
        </Box>
        <Box
          isTruncated
          maxW="full"
          fontSize="lg"
          fontWeight="semibold"
          overflowY="visible"
        >
          {mobileHeader.title}
        </Box>
        <Box pr={styles.left.pl} justifySelf="end">
          {mobileHeader.right}
        </Box>
      </Grid>
    </Container>
  )
}
